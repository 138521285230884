import useGaTracker from '../../hooks/useGaTracker';
import usePageTitle from '../../hooks/usePageTitle';
import './our-company.scss';

function Notfound() {
    useGaTracker();
    usePageTitle('About our company');
    
    return (
        <div className="our-company">
            <div className='bg__color'>
                <h1>About our company</h1>
            </div>
            <div className="wrapper-container">
                <div className='activity'>
                    <div className='activity__paragraph'>
                        <div className='flex'>
                            <span></span>
                        </div>
                        <div className='flex'>
                            <div className='company__content'>
                                <h2>2009</h2>
                                <p>Szildán Kft. was founded in 2009 with a location in Mályi. We wanted to deal with the wholesale trade of durable food products and the production and sale of our own food products.</p>
                            </div>
                        </div>
                    </div>
                    <div className='activity__paragraph'>
                        <div className='flex'>
                            <div className='company__content'>
                                <h2>2012</h2>
                                <p>Registration of trademarks. /Maestro Pietro, PURE LAND, Soul brand names/ Production of own branded products.</p>
                            </div>
                        </div>
                        <div className='flex'>
                            <span></span>
                        </div>
                    </div>
                    <div className='activity__paragraph'>
                        <div className='flex'>
                            <span></span>
                        </div>
                        <div className='flex'>
                            <div className='company__content'>
                                <h2>2016</h2>
                                <p>Szildán Kft. started operating a dry goods packaging plant by purchasing premises at Szerencs-Ond Telep Street 1. The packaged products are sold to existing wholesale partners and multinational networks.</p>
                            </div>
                        </div>
                    </div>
                    <div className='activity__paragraph'>
                        <div className='flex'>
                            <div className='company__content'>
                                <h2>2020</h2>
                                <p>The continuous product range and turnover growth required the expansion of the storage capacity, which is why a modern storage system with shelves was built. Expansion of the floor area of the cold room.</p>
                            </div>
                        </div>
                        <div className='flex'>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Notfound;