import './packaging.scss';
import ImgCsom from '../../assets/images/csom-big.png'
import useGaTracker from '../../hooks/useGaTracker';
import usePageTitle from '../../hooks/usePageTitle';

export default function() {
    useGaTracker();    
    usePageTitle('Packaging');
    
    return (
        <div className='packinging'>
            <div className='bg__color'>
                <h1>Packaging</h1>
            </div>
            <div className='wrapper-container'>
                <div className="row">
                    <div className="col-50 text-center">
                        <img className='packaging__image' src={ImgCsom} alt="" />
                    </div>
                    <div className="col-50">
                        <div className='packaging__box'>
                            <div>
                                <p>Dry goods (rice, beans, lentils...) are packed in the packaging plant.</p>
                                <p><strong>Who do we pack for?</strong></p>
                                <ul>
                                    <li>For own wholesale distribution</li>
                                    <li>Satisfying customer needs - orders from multinational companies</li>
                                    <li>Wage packing</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <iframe className='bottom__map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2659.7863616852005!2d21.228640799999997!3d48.191467599999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473f51fd5034bfd7%3A0xf83991e5d6e07a2f!2sSzild%C3%A1n%20kft%20Szerencs!5e0!3m2!1sen!2shu!4v1695744102181!5m2!1sen!2shu"  style={{border:0}} title="Szildán Kft. telephelye a Google térképen"></iframe>
            </div>
        </div>
    );
}