import { useEffect } from "react";

const usePageTitle = (page: string | undefined = undefined, override: boolean = false) => {
    useEffect(() => {
        if (override && page){
            document.title = page;
        }
        else{
            document.title = page ? page + ' - Szildán' : "Szildán";
        }
    });
};

export default usePageTitle;