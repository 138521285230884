export const data = {
  categories: [
    {
      id: "margarinok",
      title: "Margarinok",
      image: require('./assets/images/product-categories/margarin.jpg'),
      description: 'Növényi zsiradékból előállított margarinjaink reggelihez, sütéshez-főzéshez egyaránt kiválóak.',
      products: [
        {
          image: require('./assets/images/products/margarin/margarin.jpg'),
          variants: [
            'FLORIN MARGARIN 0,5KG/18',
            'FLORIN MARGARIN 1KG/12',
            'FLORIN SUTOKOCKA 60% 250G/40',
            'PURE LAND V.SUTOKOCKA70% 250g/40',
            'PURE LAND VITAMINOS MARG 25% 0,5KG/12',
            'PURE LAND VITAMINOS MARG.1KG/6'
          ]
        }
      ]
    },
    {
      id: "halkonzervek",
      title: "Halkonzervek",
      image: require('./assets/images/product-categories/halkonzerv.jpg'),
      description: 'Halkonzervjeink kiváló minőségű halfajtákból különböző ízesítéssel, szószokkal és salátaként is készülnek.',
      products: [
        {
          image: require('./assets/images/products/halkonzerv/halkonzerv.jpg'),
          variants: [
            'HAL MP. HERING NOV.OLAJBAN TZ 170G/15',
            'HAL MP. HERING PARADICS.SZOSZBAN TZ 170G/15',
            'HAL MP. HERING SALATA PARADICS.SZOSZ TZ 170G/15',
            'HAL MP. HERING SALATA PIKANS SZOSZ.TZ 170G/15',
            'HAL MP. HERING MEXIKOI TZ 170G/15',
            'HAL MP. HERING PAR.BORSOS SZOSZ TZ 170G/15',
            'HAL MP. HERING MUSTAROS SZOSZ TZ 170G/16',
            'HAL MP. SPROTNI NOV.OLAJBAN TZ 170G/15',
            'HAL MP. SPROTNI PARADICS.SZOSZBAN TZ 170G/15'
          ]
        }
      ]
    },
    {
      id: "husalapu-keszitmenyek",
      title: "Húsalapú készítmények",
      image: require('./assets/images/product-categories/husalapu-keszitmenyek.jpg'),
      description: 'Sertés és baromfihús alapú termékeink széles választékban hagyományos ízvilággal kerülnek gyártásra.',
      products: [
        {
          image: require('./assets/images/products/husalapu-keszitmenyek/torkos.jpg'),
          variants: [
            'TORKOS MELEGSZENDVICSKREM MAGYAROS 200G/6',
            'TORKOS MELEGSZENDVICSKREM HAMBURGER 200G/6',
            'TORKOS MELEGSZENDVICSKREM PIZZA 200G/6',            
            'TORKOS LIBAMAJAS HUSKÉSZITMÉNY 100G/6',
            'TORKOS KACSAMAJAS HUSKÉSZITMÉNY 100G/6',
            'TORKOS CSIRKEMAJAS HUSKÉSZITMÉNY 100G/6',
            'TORKOS SERTÉSMAJAS HUSKÉSZITMÉNY 100G/6',
            'TORKOS PAPRIKAS SERTES HUSKÉSZITMÉNY 100G/6',
            'TORKOS PULYKAMAJAS HUSKÉSZITMÉNY 100G/6',
          ]
        },
        {
          image: require('./assets/images/products/husalapu-keszitmenyek/majkr.jpg'),
          variants: [
            'MAJKR.SERTESHUSKREM MAJJAL 100G M.P./48',
            'MAJKR.SERTESHUSKREM MAJJAL 130G M.P./36',
            'MAJKR.SERTESHUSKREM MAJJAL 50G M.P./32',
            'MAJKR.SZARNYASKREM NATUR 100G M.P./48',
            'MAJKR.SZARNYASKREM NATUR 130G M.P./36',
            'MAJKR.SZARNYASKREM NATUR 50G M.P./32',
            'MAJKR.SZARNYASKREM NATUR 50G TORKOS /24',
            'MAJK. CSALADI CSIRKEMÁJJAL 200G M.P./6',
            'MAJK. CSALADI SERTESMÁJJAL 200G M.P./6',
            'SERTES LONCSHUS M.P.400GR/6',
            'DARÁLT LONCSHUS MENZA 300GR/6',
          ]
        }
      ]
    },
    {
      id: "keszetelek",
      title: "Készételek",
      image: require('./assets/images/product-categories/keszetelek.jpg'),
      images: [],
      description: 'Készételeinknél törekszünk a hazai ízek konzervbe zárt változatát nyújtani.',
      products: [
        {
          image: require('./assets/images/products/keszetelek/keszetelek.jpg'),
          variants: [
            'MP.BABFOZELEK KOLBASSZAL 400G/6',
            'MP.BABGULYAS 400G/6',
            'MP.LENCSEFOZELEK 400G/6',
            'MP.MEXIKOI CHILISBAB 400G/6',
            'MP.PACALPORKOLT 400G/6',
            'MP.PARADICS.HUSGOMBOS 400G/6',
            'MP.RAKOTT KAPOSZTA 400G/6',
            'MP.SOLET CSIP.KOLB.400G/6',
            'MP.SZEKELYKAPOSZTA 400G/6',
            'MP.TOLTOTT KAPOSZTA 400G/6',
            'MP.BABGULYAS 830G/6',
            'MP.MEXIKOI CHILISBAB 830G/6',
          ]
        }
      ]
    },
    {
      id: "savanyusagok",
      title: 'Savanyúságok',
      image: require("./assets/images/product-categories/savanyusagok.jpg"),
      description: 'Savanyúságaink között a hagyományos termékeken túl újdonságok is megtalálhatóak.',
      products: [
        {
          image: require('./assets/images/products/savanyusagok/savanyusagok.jpg'),
          variants: [
            'ALMAPAPRIKA GOLD 720ML M.P./8',
            'CEKLA GOLD720ML M.P./8',
            'CEKLA  RESZELT ECETES 720ML/500G M.P./8',
            'CHILI PAPRIKA  M.P. 720 ML/8',
            'CSEMEGE UBORKA 3-6CM 720ML/8',
            'CSEMEGE UBORKA  XXl 6-12CM 900ML M.P./8',
            'CSEMEGE UBORKA 6-9CM 720ML M.P./8   GOLD',
            'CSERESZNYEPAPRIKA M.P.  720ML/8',
            'HEGYES EROS PAPRIKA 720ML/8',
            'GOROG TIPUSU XXL SALATA GOLD M.P. 815ML/8',
            'BOLGÁR TIPUSU XXL  SALATA GOLD M.P. 815ML/8',
            'GOROG TIPUSU  SALATA GOLD M.P. 720ML/8',
            'PEPPERONI PAPRIKA M.P. GOLD 720ML/8',
            'SAVANYITOTT KAPOSZTA M.P. 720ML/8',
            'VEGYESVAGOTT 720ML M.P./8',
            'VOROSKAPOSZTA ALMAVAL M.P. 720ML/8'
          ]
        }
      ]
    },
    {
      id: "befottek",
      title: 'Befőttek',
      image: require("./assets/images/product-categories/befottek.jpg"),
      description: 'Befőttjeink válogatott gyümölcsökből akár tartósítószer hozzáadása nélkül kerülnek előállításra.',
        products: [
        {
          image: require('./assets/images/products/befottek/befottek.jpg'),
          variants: [
            'ALMA BEFŐTT SZELETELT 720ML/8',
            'SARGABARACK BEFŐTT 720ML/8',
            'CSERESZNYE BEFŐTT M.P. 720 ML/8',
            'MEGGYBEFOTT MAGOZOTT  720ML MF/8',
            'MEGGYBEFOTT MAGOZOTT 4250ML 2200G/2',
            'OSZIBARACK KONZ.FEL.850ML M.P./12',
            'SZILVABEFOTT EGESZ 720ML M.P./8',
            'SZILVABEFOTT FELEZETT FAHÉJAS 720ML  /8',
            'ANANASZ DARABOLT 565/300G GOLD',
            'ANANASZ SZELETELT 565/300G GOLD',
            'ANANASZ DARABOLT 3000/1600G GOLD'
          ]
        }
      ]
    },
    {
      id: "konzerv-zoldsegek",
      title: 'Konzerv zöldségek',
      image: require("./assets/images/product-categories/konzerv-zoldsegek.jpg"),
      description: 'Alacsony kalóriatartalmú kiváló minőségű zöldségekből konzerválva.',
      products: [
        {
          image: require('./assets/images/products/konzerv-zoldsegek/konzerv-zoldsegek.jpg'),
          variants: [
            'CSEM.KUKORICA SZUPER EDES  M.P 425ML285G TZ./10',
            'GOMBA SZEL.DOB.M.P.212ML 115G/24 GOLD',
            'GOMBA SZEL.DOB.M.P.425ML 200G/24 GOLD',
            'GOMBA SZEL.UV.M.P.314ML 170G/12',
            'GOMBA SZEL.DOB.M.P. 2650ML 1200G/6',
          ]
        },
        {
          image: require('./assets/images/products/konzerv-zoldsegek/zoldbab-zoldborso.jpeg'),
          variants: [
            'ZOLDBAB SARGAHUVELYU M.P. GOLD 720ML/8',
            'NATUR LECSO 720ML M.P./8 GOLD',
            'ZOLDBORSO M.P.  720ML/8'            
          ]
        }
      ]
    },
    {
      id: "lekvarok",
      title: "Lekvárok",
      image: require("./assets/images/product-categories/lekvarok.jpg"),
      description: 'Lekvárjaink magas gyümölcstartalommal házias ízvilággal, nap érlelte gyümölcsökből készülnek.',
      products: [
        {
          image: require('./assets/images/products/lekvarok/lekvarok.jpg'),
          variants: [
            'LEKV. SÁRGABARACK 650G 60% M.P.',
            'LEKV. MEGGY EXTRADZS. 45% GYUM. TART. M.P./8',
            'LEKV. S.BARACK EXTRADZS. 45% GYUM. TART. M.P./8',
            'LEKV. ERDEI VEGYES   EXTRADZS. 45% GYUM. TART. M.P./8',
            'LEKV. SZAMOCA EXTRADZS. 45% GYUM. TART. M.P./8',
            'NAP IZE PREMIUM LEKVAR.330G SARGABARACK/8',
            'NAP IZE PREMIUM LEKVÁR .330G VEGYES/8',
            'NAP IZE SUT.ALLO S.BARACK KESZITMÉNY 500G/16',
            'NAP IZE SUT.ALLO VEGYES KESZITMENY 500G/16'
          ]
        }
      ]
    },
    {
      id: "szoszok-martasok",
      title: "Szószok, mártások",
      image: require('./assets/images/product-categories/szoszok-martasok.jpg'),
      description: 'Megszokott ízek, jó minőség, kiváló alapanyagokból készítve.',
      products: [
        {
          image: require('./assets/images/products/szoszok-martasok/szoszok-martasok.jpg'),
          variants: [
            'KETCHUP M.P.500G /12',
            'KETCHUP M.P.900G /6',
            'MAJONEZ M.P. 450GR /12',
            'MUSTAR M.P.500G /12',
            'MARTAS M.P. BOLOGNAI 360G/6',
            'MARTAS M.P. EDES-SAVANYU 360G/6',
            'MARTAS M.P. MEXIKOI 360G/6',
            'MARTAS M.P. MILANOI 360G/6',      
            'SUR.PAR.PREM.18-20% 165G/10',
            'SUR.PAR.PREM.22-24% 150G/24'      
          ]
        }
      ]
    },
    {
      id: "edessegek",
      title: "Édességek",
      image: require('./assets/images/product-categories/edesseg.jpg'),
      description: 'Édesség kínálatunk ízletes sütemények, sütési alapanyagokból áll.',
      products: [
        {
          image: require('./assets/images/products/edesseg/edesseg.jpg'),
          variants: [
            'NATY OSTYA LEDIG KAKAOS/3KG',
            'BABAPISKOTA 200G FINCSI /15',
            'BL.KAKAOKREMMEL TOLT.OSTYARUD 160G/30',
            'BL.MARTOTT CIKK-CAKK 180G/18',
            'BL.MARTOTT KOKUSZ CSUCS 200G/18',
            'BL.MOGYOROKREMMEL TOLT.OSTYARUD 160G/30'
          ]
        }
      ]
    },
    {
      id: "gabona-termekek",
      title: "Gabona termékek",
      image: require('./assets/images/product-categories/gabona-termekek.jpg'),
      description: 'Kínálatunk ropogós reggeliző gabona pehely és golyó, valamint vanília, eper, csoki és mogyorókrémmel töltött párnák, melyek magas kakaó-, alacsony cukortartalommal, hozzáadott vitaminokkal gyártódnak.',
      products: [
        {
          image: require('./assets/images/products/gabona-termekek/gabona-termekek.jpg'),
          variants: [
            'GABONA GOLYO 250G KAKAOS /12',
            'GABONA PEHELY 250G KAKAOS/12',
            'GABONA TOLTOTT PARNA 200G EPRES/12 SOUL',
            'GABONA TOLTOTT PARNA 200G KAKAOS/12 SOUL',
            'GABONA TOLTOTT PARNA 200G VANILIAS/12 SOUL',
            'GABONA TOLTOTT PARNA 200G MOGYOROS/12 SOUL',
            'GABONA TOLTOTT FALATKA 200G CSOKOLADES PREMIUM SOUL'            
          ]
        }
      ]
    },
    {
      id: "gyumolcslevek",
      title: "Gyümölcslevek",
      image: require('./assets/images/product-categories/gyumolcslevek.jpg'),
      description: 'Közkedvelt gyümölcsökből készülnek, 20%-25%-os gyümölcstartalommal cukorral vagy édesítőszerrel pasztőrözve.',
      products: [
        {
          image: require('./assets/images/products/gyumolcslevek/gyumolcslevek-literes.jpg'),
          variants: [
            'GYUM.LE 1L ALMA 20% ZP/12',
            'GYUM.LE 1L NARANCS 20% ZP /12',
            'PARADICSOMPURE 99,5% BARON 515g/12',   
            'PARADICSOM JUICE 100% GRAND 1l/12'                             
          ]
        },
        {
          image: require('./assets/images/products/gyumolcslevek/gyumolcslevek.jpg'),
          variants: [
            'GYUM.LE 2L ALMA 20% ZP/8',
            'GYUM.LE 2L MULTIVITAMIN 25%/8',
            'GYUM.LE 2L NARANCS 20% ZP /8',
            'GYUM.LE 2L OSZI-SZOLO 20% ZP/8',
          ]
        }
      ]
    },
    {
      id: "kavek-teak",
      title: "Kávék, teák",
      image: require('./assets/images/product-categories/kave-tea.jpg'),
      description: 'Kínálatunkban megtalálhatóak a kamilla, fekete tea és a gyümölccsel ízesített teakeverékek.',
      products: [
        {
          image: require('./assets/images/products/kave-tea/kave-tea.jpg'),
          variants: [
            'CAFETERO 2IN1 14GR*10DB/8',
            'CAFETERO 3IN1 18GR*10DB/8',
            'MOKATE 3IN1 16G 20+4/6',
            'TEA MULTEANA CITROM 30GR/8',
            'TEA MULTEANA ERDEI 30GR/8',
            'TEA MULTEANA MALNA 30GR/8',
            'TEA MULTEANA MULTI 30GR/8',
            'TEA MULTEANA BARACK 30GR/8',
            'TEA MULTEANA MANGO 30GR/8',
            'TEA MULTEANA EARL GREY 30GR/8',
            'TEA MULTEANA KAMILLA 30GR/8',
            'LATTIMO KAVEFEHERITO 200G/16'
          ]
        }
      ]
    },
    {
      id: "tesztak",
      title: "Tészták",
      image: require('./assets/images/product-categories/tesztak.jpg'),
      description: '4 tojásos házias jellegű száraztészta készítmények.',
      products: [
        {
          image: require('./assets/images/products/tesztak/tesztak.jpg'),
          variants: [
            'KIR.TESZTA 4TOJ.KISKOCKA 200G/25',
            'KIR.TESZTA 4TOJ.SODROTT CSIGA 200G/25',
            'KIR.TESZTA 4TOJ.CERNAMETELT 200G/25',
            'KIR.TESZTA 4TOJ.SZELESMETELT 200G/25'
          ]
        }
      ]
    },
    {
      id: "szarazaru-termekek",
      title: "Szárazáru termékek",
      image: require('./assets/images/product-categories/szarazaru.jpg'),
      description: 'Kínálatunk kiváló minőségű alapanyagot biztosít a háziasszonyok, konyhák számára.',
      products: [
        {
          image: require('./assets/images/products/szarazaru/rizs.jpg'),
          variants: [         
            'RIZS "A" 1KG /15',
            'RIZS "A" 400G M.P./20',
            'RIZS "A" 5KG',
            'RIZS "B" 1KG /15',
            'RIZS "B" 5KG'           
          ]
        },
        {
          image: require('./assets/images/products/szarazaru/rizs-gold.jpg'),
          variants: [
            'RIZS "A" 1KG M.P./15 GOLD Hosszúszemű',
            'RIZS "A" 800G  JAZMIN/20 WELLNES',
            'RIZS "A" 400G M.P. JAZMIN/20',
            'RIZS "A" 1KG M.P. JAZMIN/15 GOLD',
            'RIZS "A" 5KG JAZMIN',
            'RIZS "B" 1KG M.P./15 GOLD',
            'RIZS BASMATI 400G M.P./20 GOLD',
            'RIZS BARNA 1 KG M.P./15 GOLD',
          ]
        },
        {
          image: require('./assets/images/products/szarazaru/hantolt.jpg'),
          variants: [
            'HANTOLT ARPA 5KG',
            'HANTOLT ARPA M.P. 400G/20',
            'FEHERBAB 5 KG LEDIG',
            'FEHERBAB MP.400G/20',
            'LENCSE 5 KG LEDIG',
            'LENCSE M.P. 400G/20',
            'SARGABORSO 400G MP/20',
            'SARGABORSO 5 KG LEDIG',
            'TARKABAB /KAN/5KG',
            'TARKABAB 400G M.P./20',
            'TARKABAB 400G P.L../20',            
          ]
        }
      ]
    },
    {
      id: "allateledelek",
      title: "Állateledelek",
      image: require('./assets/images/product-categories/allateledel.jpg'),
      description: 'Többféle összetételben különböző ízekben a házikedvencek igényeire szabva gyártódnak.',
      products: [
        {
          image: require('./assets/images/products/allateledel/aramis-kutyaeledel-konzerv.jpg'),
          variants: [
            'KUTYAEL.ARAMIS 1240G BORJU/12',
            'KUTYAEL.ARAMIS 1240G CSIRKE/12',
            'KUTYAEL.ARAMIS 1240G MARHA/12',
            'KUTYAEL.ARAMIS 415G BORJU/24',
            'KUTYAEL.ARAMIS 415G CSIRKE/24',
            'KUTYAEL.ARAMIS 415G MARHA/24',
            'ARAMIS KUTYAEL.JUNIOR 415G MARHA-ALMA/24',
          ]
        },
        {
          image: require('./assets/images/products/allateledel/aramis-kutyaeledel-konzerv-gold-silver.jpg'),
          variants: [
            'ARAMIS KUTYAEL.GOLD 1240G BAROMFI/12 30 % HUS',
            'ARAMIS KUTYAEL.GOLD 1240G MAJAS/12 30% HUS',
            'ARAMIS KUTYAEL.GOLD 1240G MARHA/12 30% HUS',
            'ARAMIS KUTYAEL.GOLD 1240G VAD/12 30% HUS',
            'ARAMIS KUTYAEL.SILVER 1240G BAROMFI/12',
            'ARAMIS KUTYAEL.SILVER 1240G MAJAS/12',
            'ARAMIS KUTYAEL.SILVER 1240G MARHA/12',
            'ARAMIS-SERENA GOLD SZALAMI CSIRKE 1KG/9',
            'ARAMIS-SERENA GOLD SZALAMI MARHA 1KG/10',
            'ARAMIS-SERENA GOLD SZALAMI VAD 1KG/11',
          ]
        },
        {
          image: require('./assets/images/products/allateledel/aramis-kutyaeledel-szaraz.jpg'),
          variants: [
            'KUTYA ARAMIS DENTAL STICK 200G/10',
            'KUTYAEL.ARAMIS SZARAZ 10KG CSIRKE',
            'KUTYAEL.ARAMIS SZARAZ 10KG MARHA',
            'KUTYAEL.ARAMIS SZARAZ 3KG CSIRKE',
            'KUTYAEL.ARAMIS SZARAZ 3KG MARHA',
          ]
        },
        {
          image: require('./assets/images/products/allateledel/serena-macskaeledel.jpg'),
          variants: [
            'MACSKAELEDEL SERENA 415G CSIRKE KONZ/24',
            'MACSKAELEDEL SERENA 415G HAL KONZ./24',
            'MACSKAELEDEL SERENA 415G MARHA KONZ./24',
            'MACSKAELEDEL SERENA SILVER 415G CSIRKE KONZ/24',
            'MACSKAELEDEL SERENA SILVER 415G MAJ KONZ./24',
            'MACSKAELEDEL SERENA SILVER 415G MARHA KONZ./24',
            'PRESTIGE MACSKA SZALAMI BARANY 400G/10',
            'PRESTIGE MACSKA SZALAMI LAZAC-GARNELA 400G/10',
            'PRESTIGE MACSKA SZALAMI NYÚL 400G/10',
            'MACSKAELEDEL SERENA 800G/20',
          ]
        }
      ]
    }
  ],
};