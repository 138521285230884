import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BsArrowRight } from 'react-icons/bs';
import './hero.scss';
import CSSTransition from 'react-transition-group/CSSTransition';

import imgMargarinok from "../../assets/images/hero/margarinok.jpg";
import imgHalKonverz from "../../assets/images/hero/hal-konzerv.jpg";
import imgSzoszokMartasok from "../../assets/images/hero/szoszok-martasok.jpg";
import imgUvegesek from "../../assets/images/hero/uvegesek.jpg";

function Hero() {
  const [inProp, setInProp] = useState(false);
  const [showCarousel, setShowCarousel] = useState(false);

  useEffect(() => {
    setInProp(true);
  }, []);

  useEffect(() => {
    setShowCarousel(true);
  }, []);

  return (
    <div className='hero'>
      <div className="container">
        <div className="row">
          <div className="text">
            <CSSTransition in={inProp} timeout={1000} classNames="slide-left">
              <div className='hero__left'>
                <div>
                  <h1>Szildán Kft <br /><span>- „Affordable quality.”</span><br /></h1>
                  <span>Wholesale distribution of durable foods, packaging of dry goods products.</span>
                  <Link className='button' to='contact'>Contact<BsArrowRight /></Link>
                </div>
              </div>
            </CSSTransition>
          </div>
          <div className="imgs">
            <CSSTransition in={showCarousel} timeout={1000} classNames="slide-right">
              <div className="hero__right">
                <Carousel autoPlay infiniteLoop showThumbs={false} showArrows={true} interval={10000} renderArrowPrev={(onClickHandler, hasPrev) =>  hasPrev && (
                      <button className="carousel-custom-arrow carousel-prev-arrow" onClick={onClickHandler}>
                        <span className="material-symbols-outlined">navigate_before</span>
                      </button>
                    )
                  }
                  renderArrowNext={(onClickHandler, hasNext) =>
                    hasNext && (
                      <button className="carousel-custom-arrow carousel-next-arrow" onClick={onClickHandler}>
                        <span className="material-symbols-outlined">navigate_next</span>
                      </button>
                    )
                  }
                >
                  <Link to="/termekek/margarin">
                    <div>
                      <img src={imgMargarinok} alt="" />
                    </div>
                  </Link>
                  <Link to="/termekek/szoszok-martasok">
                    <div>
                      <img src={imgSzoszokMartasok} alt="" />
                    </div>
                  </Link>
                  <Link to="/termekek/halkonzerv">
                    <div>
                      <img src={imgHalKonverz} alt="" />
                    </div>
                  </Link>
                  <Link to="/termekek/halkonzerv">
                    <div>
                      <img src={imgUvegesek} alt="" />
                    </div>
                  </Link>
                </Carousel>
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
