import { Link } from 'react-router-dom';
import './footer.scss';
import logo from '../../assets/images/logo.svg';
import { data } from '../../data-en';
import imgFlorinLogo from '../../assets/images/brands/florin.png';
import imgMaestroLogo from '../../assets/images/brands/maestro.png';
import imgPureLandLogo from '../../assets/images/brands/pure-land.png';
import imgSoulLogo from '../../assets/images/brands/soul.png';
import imgTorkosLogo from '../../assets/images/brands/torkos.png';
import imgAramisLogo from '../../assets/images/brands/aramis.png';
import imgSerenaLogo from '../../assets/images/brands/serena.png';
import imgMulteanaLogo from '../../assets/images/brands/multeana.png';
import imgGrandeLogo from '../../assets/images/brands/grande.png';
import imgZlotyPotokLogo from '../../assets/images/brands/zloty-potok.png';
import { FaHome } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa";
import { FaFax } from "react-icons/fa";


function Footer() {
    return (
        <footer>
            <div className="footer__top">
                <div className="container">
                    <div className="row">
                        <div className="col-25">
                            <Link to="/"><img src={logo} alt="logo" className='logo' /></Link>
                            <p>Wholesale distribution of non-perishable food items, packaging of dry goods products.</p>
                        </div>
                        <div className="col-20">
                            <h5>Information</h5>
                            <ul>
                                <li><Link to="/wholesale">Wholesale</Link></li>
                                <li><Link to="/packaging">Packaging</Link></li>
                                <li><Link to="/company">Company</Link></li>
                                <li><Link to="/products">Products</Link></li>
                                <li><Link to="/contact">Contact</Link></li>
                            </ul>
                        </div>
                        <div className="col-20">
                            <h5>Products</h5>
                            <ul>
                                {data.categories.slice(0, 5).map((category) => (
                                    <li key={category.id}>
                                        <Link to={`products/${category.id}`} key={category.id} className="category-card-link">
                                            <i className="ri-arrow-right-s-line"></i>{category.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-30 contact">
                            <h5>Contact</h5>
                            <ul className='quick__contact'>
                                <li>
                                    <FaHome />
                                    <span>
                                        <strong>HQ: </strong>downstair, 4 Aranyhal Street, 1011 Budapest<br />
                                    </span>
                                </li>
                                <li>
                                    <IoIosMail />
                                    <strong>Email: </strong>
                                    <Link to="mailto:info@szildan.hu"><span>info@szildan.hu</span></Link>,&nbsp;
                                    <Link to="mailto:szildan@szildan.hu"><span>szildan@szildan.hu</span></Link>
                                </li>
                                <li><Link to="tel:(06 46) 529 211"><FaPhone /><span><strong>Tel: </strong>(06 46) 529 211</span></Link></li>
                                <li><Link to="tel:(06 46) 529 212"><FaFax /><span><strong>Fax: </strong>(06 46) 529 212</span></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__bottom container">
                <div className='copy__logos'>
                    <span>Copyright &copy; {new Date().getFullYear()} Minden jog fenntartva. Szildán Kft.</span>
                    <div className="brands">
                        <img src={imgPureLandLogo} alt="Pureland" />
                        <img src={imgMaestroLogo} alt="Maestro" />
                        <img src={imgFlorinLogo} alt="Florin" />
                        <img src={imgSoulLogo} alt="Soul" />
                        <img src={imgTorkosLogo} alt="Torkos" />
                        <img src={imgAramisLogo} alt="Aramis" />
                        <img src={imgSerenaLogo} alt="Serena" />
                        <img src={imgMulteanaLogo} alt="Multeana" />
                        <img src={imgGrandeLogo} alt="Grande" />
                        <img src={imgZlotyPotokLogo} alt="Zloty Potok" />
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;