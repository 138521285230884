export const data = {
    categories: [
      {
        id: "margarines",
        title: "Margarines",
        image: require('./assets/images/product-categories/margarin.jpg'),
        description: 'Our margarines made from vegetable fat are excellent for breakfast, baking and cooking.',
        products: [
          {
            image: require('./assets/images/products/margarin/margarin.jpg'),
            variants: [
              'FLORIN MARGARIN 0,5KG/18',
              'FLORIN MARGARIN 1KG/12',
              'FLORIN SUTOKOCKA 60% 250G/40',
              'PURE LAND V.SUTOKOCKA70% 250g/40',
              'PURE LAND VITAMINOS MARG 25% 0,5KG/12',
              'PURE LAND VITAMINOS MARG.1KG/6'
            ]
          }
        ]
      },
      {
        id: "canned-fish",
        title: "Canned fish",
        image: require('./assets/images/product-categories/halkonzerv.jpg'),
        description: 'Our canned fish is made from high-quality fish species with various seasonings, sauces and also as a salad.',
        products: [
          {
            image: require('./assets/images/products/halkonzerv/halkonzerv.jpg'),
            variants: [
              'HAL MP. HERING NOV.OLAJBAN TZ 170G/15',
              'HAL MP. HERING PARADICS.SZOSZBAN TZ 170G/15',
              'HAL MP. HERING SALATA PARADICS.SZOSZ TZ 170G/15',
              'HAL MP. HERING SALATA PIKANS SZOSZ.TZ 170G/15',
              'HAL MP. HERING MEXIKOI TZ 170G/15',
              'HAL MP. HERING PAR.BORSOS SZOSZ TZ 170G/15',
              'HAL MP. HERING MUSTAROS SZOSZ TZ 170G/16',
              'HAL MP. SPROTNI NOV.OLAJBAN TZ 170G/15',
              'HAL MP. SPROTNI PARADICS.SZOSZBAN TZ 170G/15'
            ]
          }
        ]
      },
      {
        id: "meat-based-preparations",
        title: "Meat-based preparations",
        image: require('./assets/images/product-categories/husalapu-keszitmenyek.jpg'),
        description: 'Our products based on pork and poultry meat are produced in a wide range with traditional flavors.',
        products: [
          {
            image: require('./assets/images/products/husalapu-keszitmenyek/torkos.jpg'),
            variants: [
              'TORKOS MELEGSZENDVICSKREM MAGYAROS 200G/6',
              'TORKOS MELEGSZENDVICSKREM HAMBURGER 200G/6',
              'TORKOS MELEGSZENDVICSKREM PIZZA 200G/6',            
              'TORKOS LIBAMAJAS HUSKÉSZITMÉNY 100G/6',
              'TORKOS KACSAMAJAS HUSKÉSZITMÉNY 100G/6',
              'TORKOS CSIRKEMAJAS HUSKÉSZITMÉNY 100G/6',
              'TORKOS SERTÉSMAJAS HUSKÉSZITMÉNY 100G/6',
              'TORKOS PAPRIKAS SERTES HUSKÉSZITMÉNY 100G/6',
              'TORKOS PULYKAMAJAS HUSKÉSZITMÉNY 100G/6',
            ]
          },
          {
            image: require('./assets/images/products/husalapu-keszitmenyek/majkr.jpg'),
            variants: [
              'MAJKR.SERTESHUSKREM MAJJAL 100G M.P./48',
              'MAJKR.SERTESHUSKREM MAJJAL 130G M.P./36',
              'MAJKR.SERTESHUSKREM MAJJAL 50G M.P./32',
              'MAJKR.SZARNYASKREM NATUR 100G M.P./48',
              'MAJKR.SZARNYASKREM NATUR 130G M.P./36',
              'MAJKR.SZARNYASKREM NATUR 50G M.P./32',
              'MAJKR.SZARNYASKREM NATUR 50G TORKOS /24',
              'MAJK. CSALADI CSIRKEMÁJJAL 200G M.P./6',
              'MAJK. CSALADI SERTESMÁJJAL 200G M.P./6',
              'SERTES LONCSHUS M.P.400GR/6',
              'DARÁLT LONCSHUS MENZA 300GR/6',
            ]
          }
        ]
      },
      {
        id: "prepared-meals",
        title: "Prepared meals",
        image: require('./assets/images/product-categories/keszetelek.jpg'),
        images: [],
        description: 'With our prepared meals we strive to offer canned versions of domestic flavors.',
        products: [
          {
            image: require('./assets/images/products/keszetelek/keszetelek.jpg'),
            variants: [
              'MP.BABFOZELEK KOLBASSZAL 400G/6',
              'MP.BABGULYAS 400G/6',
              'MP.LENCSEFOZELEK 400G/6',
              'MP.MEXIKOI CHILISBAB 400G/6',
              'MP.PACALPORKOLT 400G/6',
              'MP.PARADICS.HUSGOMBOS 400G/6',
              'MP.RAKOTT KAPOSZTA 400G/6',
              'MP.SOLET CSIP.KOLB.400G/6',
              'MP.SZEKELYKAPOSZTA 400G/6',
              'MP.TOLTOTT KAPOSZTA 400G/6',
              'MP.BABGULYAS 830G/6',
              'MP.MEXIKOI CHILISBAB 830G/6',
            ]
          }
        ]
      },
      {
        id: "pickles",
        title: 'Pickles',
        image: require("./assets/images/product-categories/savanyusagok.jpg"),
        description: 'In addition to traditional products our pickles also include novelties.',
        products: [
          {
            image: require('./assets/images/products/savanyusagok/savanyusagok.jpg'),
            variants: [
              'ALMAPAPRIKA GOLD 720ML M.P./8',
              'CEKLA GOLD720ML M.P./8',
              'CEKLA  RESZELT ECETES 720ML/500G M.P./8',
              'CHILI PAPRIKA  M.P. 720 ML/8',
              'CSEMEGE UBORKA 3-6CM 720ML/8',
              'CSEMEGE UBORKA  XXl 6-12CM 900ML M.P./8',
              'CSEMEGE UBORKA 6-9CM 720ML M.P./8   GOLD',
              'CSERESZNYEPAPRIKA M.P.  720ML/8',
              'HEGYES EROS PAPRIKA 720ML/8',
              'GOROG TIPUSU XXL SALATA GOLD M.P. 815ML/8',
              'BOLGÁR TIPUSU XXL  SALATA GOLD M.P. 815ML/8',
              'GOROG TIPUSU  SALATA GOLD M.P. 720ML/8',
              'PEPPERONI PAPRIKA M.P. GOLD 720ML/8',
              'SAVANYITOTT KAPOSZTA M.P. 720ML/8',
              'VEGYESVAGOTT 720ML M.P./8',
              'VOROSKAPOSZTA ALMAVAL M.P. 720ML/8'
            ]
          }
        ]
      },
      {
        id: "canned-fruits",
        title: 'Canned fruits',
        image: require("./assets/images/product-categories/befottek.jpg"),
        description: 'Our preserves are made from selected fruits without the addition of preservatives.',
          products: [
          {
            image: require('./assets/images/products/befottek/befottek.jpg'),
            variants: [
              'ALMA BEFŐTT SZELETELT 720ML/8',
              'SARGABARACK BEFŐTT 720ML/8',
              'CSERESZNYE BEFŐTT M.P. 720 ML/8',
              'MEGGYBEFOTT MAGOZOTT  720ML MF/8',
              'MEGGYBEFOTT MAGOZOTT 4250ML 2200G/2',
              'OSZIBARACK KONZ.FEL.850ML M.P./12',
              'SZILVABEFOTT EGESZ 720ML M.P./8',
              'SZILVABEFOTT FELEZETT FAHÉJAS 720ML  /8',
              'ANANASZ DARABOLT 565/300G GOLD',
              'ANANASZ SZELETELT 565/300G GOLD',
              'ANANASZ DARABOLT 3000/1600G GOLD'
            ]
          }
        ]
      },
      {
        id: "canned-vegetables",
        title: 'Canned vegetables',
        image: require("./assets/images/product-categories/konzerv-zoldsegek.jpg"),
        description: 'Canned from low-calorie, high-quality vegetables.',
        products: [
          {
            image: require('./assets/images/products/konzerv-zoldsegek/konzerv-zoldsegek.jpg'),
            variants: [
              'CSEM.KUKORICA SZUPER EDES  M.P 425ML285G TZ./10',
              'GOMBA SZEL.DOB.M.P.212ML 115G/24 GOLD',
              'GOMBA SZEL.DOB.M.P.425ML 200G/24 GOLD',
              'GOMBA SZEL.UV.M.P.314ML 170G/12',
              'GOMBA SZEL.DOB.M.P. 2650ML 1200G/6',
            ]
          },
          {
            image: require('./assets/images/products/konzerv-zoldsegek/zoldbab-zoldborso.jpeg'),
            variants: [
              'ZOLDBAB SARGAHUVELYU M.P. GOLD 720ML/8',
              'NATUR LECSO 720ML M.P./8 GOLD',
              'ZOLDBORSO M.P.  720ML/8'            
            ]
          }
        ]
      },
      {
        id: "jams",
        title: "Jams",
        image: require("./assets/images/product-categories/lekvarok.jpg"),
        description: 'Our jams are made from sun-ripened fruits with a high fruit content and homemade taste.',
        products: [
          {
            image: require('./assets/images/products/lekvarok/lekvarok.jpg'),
            variants: [
              'LEKV. SÁRGABARACK 650G 60% M.P.',
              'LEKV. MEGGY EXTRADZS. 45% GYUM. TART. M.P./8',
              'LEKV. S.BARACK EXTRADZS. 45% GYUM. TART. M.P./8',
              'LEKV. ERDEI VEGYES   EXTRADZS. 45% GYUM. TART. M.P./8',
              'LEKV. SZAMOCA EXTRADZS. 45% GYUM. TART. M.P./8',
              'NAP IZE PREMIUM LEKVAR.330G SARGABARACK/8',
              'NAP IZE PREMIUM LEKVÁR .330G VEGYES/8',
              'NAP IZE SUT.ALLO S.BARACK KESZITMÉNY 500G/16',
              'NAP IZE SUT.ALLO VEGYES KESZITMENY 500G/16'
            ]
          }
        ]
      },
      {
        id: "sauces",
        title: "Sauces",
        image: require('./assets/images/product-categories/szoszok-martasok.jpg'),
        description: 'Familiar flavors, good quality, made from excellent ingredients.',
        products: [
          {
            image: require('./assets/images/products/szoszok-martasok/szoszok-martasok.jpg'),
            variants: [
              'KETCHUP M.P.500G /12',
              'KETCHUP M.P.900G /6',
              'MAJONEZ M.P. 450GR /12',
              'MUSTAR M.P.500G /12',
              'MARTAS M.P. BOLOGNAI 360G/6',
              'MARTAS M.P. EDES-SAVANYU 360G/6',
              'MARTAS M.P. MEXIKOI 360G/6',
              'MARTAS M.P. MILANOI 360G/6',      
              'SUR.PAR.PREM.18-20% 165G/10',
              'SUR.PAR.PREM.22-24% 150G/24'      
            ]
          }
        ]
      },
      {
        id: "sweets",
        title: "Sweets",
        image: require('./assets/images/product-categories/edesseg.jpg'),
        description: 'Our selection of sweets consists of tasty cakes and baking ingredients.',
        products: [
          {
            image: require('./assets/images/products/edesseg/edesseg.jpg'),
            variants: [
              'NATY OSTYA LEDIG KAKAOS/3KG',
              'BABAPISKOTA 200G FINCSI /15',
              'BL.KAKAOKREMMEL TOLT.OSTYARUD 160G/30',
              'BL.MARTOTT CIKK-CAKK 180G/18',
              'BL.MARTOTT KOKUSZ CSUCS 200G/18',
              'BL.MOGYOROKREMMEL TOLT.OSTYARUD 160G/30'
            ]
          }
        ]
      },
      {
        id: "grain-products",
        title: "Grain products",
        image: require('./assets/images/product-categories/gabona-termekek.jpg'),
        description: 'Our offer is crunchy breakfast cereal flakes and balls, as well as pillows filled with vanilla, strawberry, chocolate and hazelnut cream, which are made with high cocoa, low sugar content and added vitamins.',
        products: [
          {
            image: require('./assets/images/products/gabona-termekek/gabona-termekek.jpg'),
            variants: [
              'GABONA GOLYO 250G KAKAOS /12',
              'GABONA PEHELY 250G KAKAOS/12',
              'GABONA TOLTOTT PARNA 200G EPRES/12 SOUL',
              'GABONA TOLTOTT PARNA 200G KAKAOS/12 SOUL',
              'GABONA TOLTOTT PARNA 200G VANILIAS/12 SOUL',
              'GABONA TOLTOTT PARNA 200G MOGYOROS/12 SOUL',
              'GABONA TOLTOTT FALATKA 200G CSOKOLADES PREMIUM SOUL'            
            ]
          }
        ]
      },
      {
        id: "juices",
        title: "Juices",
        image: require('./assets/images/product-categories/gyumolcslevek.jpg'),
        description: 'Made from popular fruits, with 20%-25% fruit content pasteurized with sugar or sweetener. ',
        products: [
          {
            image: require('./assets/images/products/gyumolcslevek/gyumolcslevek-literes.jpg'),
            variants: [
              'GYUM.LE 1L ALMA 20% ZP/12',
              'GYUM.LE 1L NARANCS 20% ZP /12',
              'PARADICSOMPURE 99,5% BARON 515g/12',   
              'PARADICSOM JUICE 100% GRAND 1l/12'                             
            ]
          },
          {
            image: require('./assets/images/products/gyumolcslevek/gyumolcslevek.jpg'),
            variants: [
              'GYUM.LE 2L ALMA 20% ZP/8',
              'GYUM.LE 2L MULTIVITAMIN 25%/8',
              'GYUM.LE 2L NARANCS 20% ZP /8',
              'GYUM.LE 2L OSZI-SZOLO 20% ZP/8',
            ]
          }
        ]
      },
      {
        id: "coffees-teas",
        title: "Coffees, teas",
        image: require('./assets/images/product-categories/kave-tea.jpg'),
        description: 'Our offer includes chamomile, black tea and tea blends flavored with fruit.',
        products: [
          {
            image: require('./assets/images/products/kave-tea/kave-tea.jpg'),
            variants: [
              'CAFETERO 2IN1 14GR*10DB/8',
              'CAFETERO 3IN1 18GR*10DB/8',
              'MOKATE 3IN1 16G 20+4/6',
              'TEA MULTEANA CITROM 30GR/8',
              'TEA MULTEANA ERDEI 30GR/8',
              'TEA MULTEANA MALNA 30GR/8',
              'TEA MULTEANA MULTI 30GR/8',
              'TEA MULTEANA BARACK 30GR/8',
              'TEA MULTEANA MANGO 30GR/8',
              'TEA MULTEANA EARL GREY 30GR/8',
              'TEA MULTEANA KAMILLA 30GR/8',
              'LATTIMO KAVEFEHERITO 200G/16'
            ]
          }
        ]
      },
      {
        id: "noodles",
        title: "Noodles",
        image: require('./assets/images/product-categories/tesztak.jpg'),
        description: '4-egg home-made dry pasta preparations.',
        products: [
          {
            image: require('./assets/images/products/tesztak/tesztak.jpg'),
            variants: [
              'KIR.TESZTA 4TOJ.KISKOCKA 200G/25',
              'KIR.TESZTA 4TOJ.SODROTT CSIGA 200G/25',
              'KIR.TESZTA 4TOJ.CERNAMETELT 200G/25',
              'KIR.TESZTA 4TOJ.SZELESMETELT 200G/25'
            ]
          }
        ]
      },
      {
        id: "dry-goods",
        title: "Dry goods",
        image: require('./assets/images/product-categories/szarazaru.jpg'),
        description: 'our range provides high-quality ingredients for housewives and kitchens.',
        products: [
          {
            image: require('./assets/images/products/szarazaru/rizs.jpg'),
            variants: [         
              'RIZS "A" 1KG /15',
              'RIZS "A" 400G M.P./20',
              'RIZS "A" 5KG',
              'RIZS "B" 1KG /15',
              'RIZS "B" 5KG'           
            ]
          },
          {
            image: require('./assets/images/products/szarazaru/rizs-gold.jpg'),
            variants: [
              'RIZS "A" 1KG M.P./15 GOLD Hosszúszemű',
              'RIZS "A" 800G  JAZMIN/20 WELLNES',
              'RIZS "A" 400G M.P. JAZMIN/20',
              'RIZS "A" 1KG M.P. JAZMIN/15 GOLD',
              'RIZS "A" 5KG JAZMIN',
              'RIZS "B" 1KG M.P./15 GOLD',
              'RIZS BASMATI 400G M.P./20 GOLD',
              'RIZS BARNA 1 KG M.P./15 GOLD',
            ]
          },
          {
            image: require('./assets/images/products/szarazaru/hantolt.jpg'),
            variants: [
              'HANTOLT ARPA 5KG',
              'HANTOLT ARPA M.P. 400G/20',
              'FEHERBAB 5 KG LEDIG',
              'FEHERBAB MP.400G/20',
              'LENCSE 5 KG LEDIG',
              'LENCSE M.P. 400G/20',
              'SARGABORSO 400G MP/20',
              'SARGABORSO 5 KG LEDIG',
              'TARKABAB /KAN/5KG',
              'TARKABAB 400G M.P./20',
              'TARKABAB 400G P.L../20',            
            ]
          }
        ]
      },
      {
        id: "pet-foods",
        title: "Pet foods",
        image: require('./assets/images/product-categories/allateledel.jpg'),
        description: 'In various compositions and flavors, they are produced tailored to the needs of pets.',
        products: [
          {
            image: require('./assets/images/products/allateledel/aramis-kutyaeledel-konzerv.jpg'),
            variants: [
              'KUTYAEL.ARAMIS 1240G BORJU/12',
              'KUTYAEL.ARAMIS 1240G CSIRKE/12',
              'KUTYAEL.ARAMIS 1240G MARHA/12',
              'KUTYAEL.ARAMIS 415G BORJU/24',
              'KUTYAEL.ARAMIS 415G CSIRKE/24',
              'KUTYAEL.ARAMIS 415G MARHA/24',
              'ARAMIS KUTYAEL.JUNIOR 415G MARHA-ALMA/24',
            ]
          },
          {
            image: require('./assets/images/products/allateledel/aramis-kutyaeledel-konzerv-gold-silver.jpg'),
            variants: [
              'ARAMIS KUTYAEL.GOLD 1240G BAROMFI/12 30 % HUS',
              'ARAMIS KUTYAEL.GOLD 1240G MAJAS/12 30% HUS',
              'ARAMIS KUTYAEL.GOLD 1240G MARHA/12 30% HUS',
              'ARAMIS KUTYAEL.GOLD 1240G VAD/12 30% HUS',
              'ARAMIS KUTYAEL.SILVER 1240G BAROMFI/12',
              'ARAMIS KUTYAEL.SILVER 1240G MAJAS/12',
              'ARAMIS KUTYAEL.SILVER 1240G MARHA/12',
              'ARAMIS-SERENA GOLD SZALAMI CSIRKE 1KG/9',
              'ARAMIS-SERENA GOLD SZALAMI MARHA 1KG/10',
              'ARAMIS-SERENA GOLD SZALAMI VAD 1KG/11',
            ]
          },
          {
            image: require('./assets/images/products/allateledel/aramis-kutyaeledel-szaraz.jpg'),
            variants: [
              'KUTYA ARAMIS DENTAL STICK 200G/10',
              'KUTYAEL.ARAMIS SZARAZ 10KG CSIRKE',
              'KUTYAEL.ARAMIS SZARAZ 10KG MARHA',
              'KUTYAEL.ARAMIS SZARAZ 3KG CSIRKE',
              'KUTYAEL.ARAMIS SZARAZ 3KG MARHA',
            ]
          },
          {
            image: require('./assets/images/products/allateledel/serena-macskaeledel.jpg'),
            variants: [
              'MACSKAELEDEL SERENA 415G CSIRKE KONZ/24',
              'MACSKAELEDEL SERENA 415G HAL KONZ./24',
              'MACSKAELEDEL SERENA 415G MARHA KONZ./24',
              'MACSKAELEDEL SERENA SILVER 415G CSIRKE KONZ/24',
              'MACSKAELEDEL SERENA SILVER 415G MAJ KONZ./24',
              'MACSKAELEDEL SERENA SILVER 415G MARHA KONZ./24',
              'PRESTIGE MACSKA SZALAMI BARANY 400G/10',
              'PRESTIGE MACSKA SZALAMI LAZAC-GARNELA 400G/10',
              'PRESTIGE MACSKA SZALAMI NYÚL 400G/10',
              'MACSKAELEDEL SERENA 800G/20',
            ]
          }
        ]
      }
    ],
  };