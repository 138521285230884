import './contact.scss';
import { MdLocationPin, MdEmail } from 'react-icons/md';
import { BsFillTelephoneFill } from 'react-icons/bs';
import useGaTracker from '../../hooks/useGaTracker';
import usePageTitle from '../../hooks/usePageTitle';

function Contactus() {
    useGaTracker();
    usePageTitle('Kapcsolat');
    
    return (
        <div>
            <div className='bg__color'>
                <h1>Kapcsolat</h1>
            </div>
            <div className="wrapper-container mb-90">
                <div className="row">
                    <div className="row contact__align">
                        <div className="col-33">
                            <MdLocationPin />
                            <p>
                                <a>Székhely: HU-1011 Budapest,<br/> Aranyhal utca 4. fszt. 1.</a>
                            </p>
                        </div>
                        <div className="col-33">
                            <MdEmail />
                            <p>
                                <a>info@szildan.hu</a>
                                <a>szildan@szildan.hu</a>
                            </p>
                        </div>
                        <div className="col-33">
                            <BsFillTelephoneFill />
                            <p>
                                <a>Tel: (06 46) 529 211</a>
                                <a>Fax: (06 46) 529 212</a>
                            </p>
                        </div>
                    </div>
                 </div>
                <div className="row">
                    <h2 className='site'>Telephelyeink</h2>
                    <div className="row">
                        <div className="col-50">
                            <p>
                                HU-3434 Mályi, Kistokaji út 7.<br />
                                GPS: 48.028694, 20.820917
                            </p>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2668.2186473540396!2d20.817254014651553!3d48.02880365803325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4740a16066f96e83%3A0xecdc2ba2ea18de84!2sSzild%C3%A1n%20Kft!5e0!3m2!1sen!2shu!4v1682667727369!5m2!1sen!2shu" style={{ border: 0 }} title="Szildán Kft. telephelye a Google térképen"></iframe>
                        </div>  
                        <div className="col-50">
                            <p>	
                                HU-3902 Szerencs-Ond, Telep út 1.<br />
                                GPS: 48.191524, 21.228662
                            </p>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2659.7863616852005!2d21.228640799999997!3d48.191467599999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473f51fd5034bfd7%3A0xf83991e5d6e07a2f!2sSzild%C3%A1n%20kft%20Szerencs!5e0!3m2!1sen!2shu!4v1695744102181!5m2!1sen!2shu" style={{ border: 0 }} title="Szildán Kft. telephelye a Google térképen"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contactus;