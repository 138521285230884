import { Link } from 'react-router-dom';
import './footer.scss';
import logo from '../../assets/images/logo.svg';
import { data } from '../../data';
import szechenyi from '../../assets/images/szechenyi.png'
import imgFlorinLogo from '../../assets/images/brands/florin.png';
import imgMaestroLogo from '../../assets/images/brands/maestro.png';
import imgPureLandLogo from '../../assets/images/brands/pure-land.png';
import imgSoulLogo from '../../assets/images/brands/soul.png';
import imgTorkosLogo from '../../assets/images/brands/torkos.png';
import imgAramisLogo from '../../assets/images/brands/aramis.png';
import imgSerenaLogo from '../../assets/images/brands/serena.png';
import imgMulteanaLogo from '../../assets/images/brands/multeana.png';
import imgGrandeLogo from '../../assets/images/brands/grande.png';
import imgZlotyPotokLogo from '../../assets/images/brands/zloty-potok.png';
import { FaHome } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { FaPhone } from "react-icons/fa";
import { FaFax } from "react-icons/fa";

function Footer() {
    return (
        <div>
            <footer>
                <div className="footer__top">
                    <div className="container">
                        <div className="row">
                            <div className="col-25">
                                <Link to="/"><img src={logo} alt="logo" className='logo' /></Link>
                                <p>Tartós élelmiszerek nagykereskedelmi forgalmazása, szárazáru termékek csomagolása.</p>
                            </div>
                            <div className="col-20">
                                <h5>Információ</h5>
                                <ul>
                                    <li><Link to="/nagykereskedelem">Nagykereskedelem</Link></li>
                                    <li><Link to="/csomagolouzem">Csomagolóüzem</Link></li>
                                    <li><Link to="/cegunkrol">Cégünkről</Link></li>
                                    <li><Link to="/termekek">Termékek</Link></li>
                                    <li><Link to="/kapcsolat">Kapcsolat</Link></li>
                                </ul>
                            </div>
                            <div className="col-20">
                                <h5>Termékek</h5>
                                <ul>
                                    {data.categories.slice(0, 5).map((category) => (
                                        <li key={category.id}>
                                            <Link to={`termekek/${category.id}`} key={category.id} className="category-card-link">
                                                <i className="ri-arrow-right-s-line"></i>{category.title}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="col-30 contact">
                                <h5>Kapcsolat</h5>
                                <ul className='quick__contact'>
                                    <li>
                                        <FaHome />
                                        <span>
                                            <strong>Székhely: </strong>1011 Budapest, Aranyhal utca 4. fszt. 1.<br />
                                        </span>
                                    </li>
                                    <li>
                                        <IoIosMail />
                                        <strong>Email: </strong>
                                        <Link to="mailto:info@szildan.hu"><span>info@szildan.hu</span></Link>,&nbsp;
                                        <Link to="mailto:szildan@szildan.hu"><span>szildan@szildan.hu</span></Link>
                                    </li>
                                    <li><Link to="tel:(06 46) 529 211"><FaPhone /><span><strong>Tel: </strong>(06 46) 529 211</span></Link></li>
                                    <li><Link to="tel:(06 46) 529 212"><FaFax /><span><strong>Fax: </strong>(06 46) 529 212</span></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer__bottom container">
                    <div className='copy__logos'>
                        <span>Copyright &copy; {new Date().getFullYear()} Minden jog fenntartva. Szildán Kft.</span>
                        <div className="brands">
                            <img src={imgPureLandLogo} alt="Pureland" />
                            <img src={imgMaestroLogo} alt="Maestro" />
                            <img src={imgFlorinLogo} alt="Florin" />
                            <img src={imgSoulLogo} alt="Soul" />
                            <img src={imgTorkosLogo} alt="Torkos" />
                            <img src={imgAramisLogo} alt="Aramis" />
                            <img src={imgSerenaLogo} alt="Serena" />
                            <img src={imgMulteanaLogo} alt="Multeana" />
                            <img src={imgGrandeLogo} alt="Grande" />
                            <img src={imgZlotyPotokLogo} alt="Zloty Potok" />
                        </div>
                    </div>
                </div>
                <Link to="/palyazatok">
                    <img src={szechenyi} className='fix-image' alt="Széchenyi logo" />
                </Link>
            </footer>
        </div>
    );
}

export default Footer;