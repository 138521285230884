import './products.scss';
import { useParams } from 'react-router-dom';
import { data } from '../../data';
import useGaTracker from '../../hooks/useGaTracker';
import usePageTitle from '../../hooks/usePageTitle';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Products() {
    useGaTracker();
    usePageTitle('Termékek');

    const params = useParams();
    let category;
    let categoryId = params.category;

    let products;
    if (categoryId) {
        category = data.categories.find((category) => category.id === categoryId);
        products = category ? category.products : [];
    } else {
        products = data.categories.flatMap((category) => category.products);
    }

    return (
        <div className="products">
            <div className='bg__color'>
                <h1>Termékek</h1>
            </div>
            <div className='wrapper-container'>
                <div className='buttons'>
                    <a className={`button ${categoryId === null ? 'active' : ''}`} href="/termekek">Összes</a>
                    {data.categories.map((category) => (
                        <a key={category.id} className={`button ${categoryId === category.id ? 'active' : ''}`} href={`/termekek/${category.id}`}>
                            {category.title}
                        </a>
                    ))}
                </div>
                {products.map((product, index) => (
                    <div key={index} className='variant-container'>
                        <div className='image'>
                            <LazyLoadImage alt="Termék" src={product.image} />
                        </div>
                        <div className='list'>
                            {product.variants.map((variant, variantIndex) => (
                                <div key={variantIndex}>
                                    {variant}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Products;
