import './home.scss';
import { data } from '../../data';
import { Link } from 'react-router-dom';
import Hero from '../../components/hero/hero';
import { BsArrowRight } from 'react-icons/bs'
import imgPlaceholder from '../../assets/images/placeholder.jpg';
import imgWholesale from '../../assets/images/wholesale.png';
import imgFlorinLogo from '../../assets/images/brands/florin.png';
import imgMaestroLogo from '../../assets/images/brands/maestro.png';
import imgPureLandLogo from '../../assets/images/brands/pure-land.png';
import imgSoulLogo from '../../assets/images/brands/soul.png';
import imgMainCsom from '../../assets/images/maincsom.png'
import useGaTracker from '../../hooks/useGaTracker';
import usePageTitle from '../../hooks/usePageTitle';
import { LazyLoadImage } from 'react-lazy-load-image-component';

function Home() {    
    useGaTracker();
    usePageTitle('Szildán - A megfizethető minőség', true);

    return (
        <div className='home'>
            <Hero />
            <div className="wrapper-container">
                <div className="row mb-90">
                    <div className="col-50">
                        <div className='highlighted'>
                            <div className="content">
                                <div>
                                    <h1 className="title">Nagykereskedelem</h1>
                                    <p className='above__button'>
                                        Nagykereskedelmi raktárunkban 2009-óta arra törekszünk, hogy állandó
                                        termékeink mellett folyamatosan bővüljön árukínálatunk.
                                    </p>
                                    <Link to="nagykereskedelem" className="button">Tovább<BsArrowRight /></Link>
                                </div>
                            </div>
                            <div className="thumbnail">
                                <img src={imgWholesale} alt="Nagykereskedelem" />
                            </div>
                        </div>
                    </div>
                    <div className="col-50">
                        <div className='highlighted'>
                            <div className="content">
                                <div>
                                    <h1 className="title">Csomagolóüzem</h1>
                                    <p className='above__button'>A csomagolóüzemben szárazáru csomagolása szakértő kezekben történik, hogy minőségi termékekkel láthassuk el vásárlóinkat.</p>
                                    <Link to="csomagolouzem" className="button">Tovább<BsArrowRight /></Link>
                                </div>
                            </div>
                            <div className="thumbnail">
                                <img src={imgMainCsom} alt="Csomagolóüzem" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="home__title">
                    <h1>Termékek</h1>
                    <div className="divider"></div>
                </div>
                <div className="card">
                    {data.categories.map((category) => (
                        <Link to={`termekek/${category.id}`} key={category.id} className="category-card-link">
                            <div className="card-box">
                                <div className="card-info">
                                    <div className="image-box">
                                        <LazyLoadImage alt={category.title} src={category.image ? category.image : imgPlaceholder} />
                                    </div>
                                    <div className='card__height'>
                                        <h1 className="product__title">{category.title}</h1>
                                        <p>{category.description}</p>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
            <div className="section-title">
                <h1>Kiemelt márkák</h1>
            </div>
            <div className="wrapper-container">
                <div className="highlighted-brands">
                    <div className='pure-land'>                     
                        <LazyLoadImage alt="Pure Land" src={imgPureLandLogo} />
                    </div>
                    <div className='maestro'>
                        <LazyLoadImage alt="Maestro" src={imgMaestroLogo} />
                    </div>
                    <div className='florin'>
                        <LazyLoadImage alt="Florin" src={imgFlorinLogo} />
                    </div>
                    <div className='soul'>
                        <LazyLoadImage alt="Soul" src={imgSoulLogo} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
