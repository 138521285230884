import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

import Layout from "./components/layout/layout";
import LayoutEn from "./components/layout/layout-en";
// import ScrollToTop from "./components/scrolltotop/scrolltotop";
import Home from "./pages/home/home";
import HomeEn from "./pages/home/home-en";
import Notfound from "./pages/not-found/not-found";
import Wholesale from "./pages/wholesale/wholesale";
import Contact from "./pages/contact/contact";
import ContactEn from "./pages/contact/contact-en";
import Packaging from "./pages/packaging/packaging";
import Products from "./pages/products/products";
import ProductsEn from "./pages/products/products-en";
import Company from "./pages/our-company/our-company";
import CompanyEn from "./pages/our-company/our-company-en";
import WholesaleEn from "./pages/wholesale/wholesale-en";
import PackagingEn from "./pages/packaging/packaging-en";
import Competition from "./pages/competition/competition";

function App() {
  function ScrollToTopOnNavigate() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="/scripts/quantcast.js"></script>
      </Helmet>

      <BrowserRouter>
        <ScrollToTopOnNavigate />
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="cegunkrol" element={<Company />} />
            <Route path="nagykereskedelem" element={<Wholesale />} />
            <Route path="termekek" element={<Products />} />
            <Route path="termekek/:category" element={<Products />} />
            <Route path="kapcsolat" element={<Contact />} />
            <Route path="csomagolouzem" element={<Packaging />} />
            <Route path="*" element={<Notfound />} />
            <Route path="palyazatok" element={<Competition />} />

          </Route>
          <Route element={<LayoutEn />}>
            <Route path="home" index element={<HomeEn />} />
            <Route path="company" index element={<CompanyEn />} />
            <Route path="wholesale" index element={<WholesaleEn />} />
            <Route path="products" index element={<ProductsEn />} />
            <Route path="products/:category" index element={<ProductsEn />} />
            <Route path="contact" index element={<ContactEn />} />
            <Route path="packaging" index element={<PackagingEn />} />
          </Route>
        </Routes>
        {/* <ScrollToTop /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;