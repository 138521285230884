import { Link } from 'react-router-dom';
import './not-found.scss';
import useGaTracker from '../../hooks/useGaTracker';
import usePageTitle from '../../hooks/usePageTitle';

function Notfound() {
    useGaTracker();
    usePageTitle('404');
    
    return (
        <div className='page-404'>
            <div className="nopage">
                <h1>404-es hiba</h1>
                <p>A keresett oldal sajnos nem található.</p>
                <Link className='button' to='/'>Kezdőlap</Link>
            </div>
        </div>
    );
}

export default Notfound;