import { Outlet } from 'react-router-dom';
import Header from '../header/header-en';
import Footer from '../footer/footer-en';

function Layout() {
    return (
        <div>
            <>
                <Header />
                <Outlet />
                <Footer />
            </>
        </div>
    );
}

export default Layout;