import './wholesale.scss';

import wholesale from '../../assets/images/wholesale-big.png';
import useGaTracker from '../../hooks/useGaTracker';
import usePageTitle from '../../hooks/usePageTitle';

export default function () {
    useGaTracker();
    usePageTitle('Nagykereskedelem');

    return (
        <> 
            <div>
                <div className='bg__color'>
                    <h1>Nagykereskedelem</h1>
                </div>
                <div className='wrapper-container'>
                    <div className="row wholesale">
                        <div className="col-50">
                            <img className='wholesale__img' src={wholesale} alt="" />
                        </div>
                        <div className="col-50">
                            <div className='wholesale__box'>
                                <div>
                                    <p>
                                        Nagykereskedelmi raktárunkban 2009-óta arra törekszünk, hogy állandó termékeink mellett folyamatosan bővüljön árukínálatunk.
                                    </p>
                                    <p>
                                        Elsősorban nagykereskedelmi egységek megrendeléseit teljesítjük, mára már az
                                        ország egész területére szállítunk, szinte minden megyében fellelhetőek továbbértékesítő
                                        partnereink.
                                    </p>
                                    <p>
                                        Büszkék vagyunk arra, hogy folyamatosan bővülő megrendelőink körében
                                        nagy számban megtalálhatóak azok a cégek, akik a kezdetektől vásárolnak tőlünk.
                                    </p>
                                    <p>
                                        Mindig fontos szempont volt számunkra, hogy forgalmazott termékeink jó minőséget
                                        képviseljenek, de megfizethetőek legyenek egy széleskörű fogyasztói rétegnek.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <iframe className='bottom__map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2668.2186473540396!2d20.817254014651553!3d48.02880365803325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4740a16066f96e83%3A0xecdc2ba2ea18de84!2sSzild%C3%A1n%20Kft!5e0!3m2!1sen!2shu!4v1682667727369!5m2!1sen!2shu" style={{ border: 0 }} title="Szildán Kft. telephelye a Google térképen"></iframe>
                </div>
            </div>
        </>
    );
}