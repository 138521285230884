import './header.scss';
import { NavLink, Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import logo from '../../assets/images/logo.svg';
import { RxHamburgerMenu } from "react-icons/rx"

function Header() {
    const [scrolled, setScrolled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [shouldClose, setShouldClose] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const handleClose = () => {
        setShouldClose(true);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (shouldClose) {
            setIsOpen(false);
            setShouldClose(false);
        }
    }, [shouldClose]);

    return (
        <header className={scrolled ? "sticky" : ""}>
            <div className="container">
                <div className="main-header">
                    <Link className="logo" to="/home"><img src={logo} alt="logo" className='logo' /></Link>
                    <nav>
                        <ul className="nav__links">
                            <li><NavLink to="/wholesale">Wholesale</NavLink></li>
                            <li><NavLink to="/packaging">Packaging</NavLink></li>
                            <li><NavLink to="/company">Company</NavLink></li>
                            <li><NavLink to="/products">Products</NavLink></li>
                            <li><NavLink to="/contact">Contact</NavLink></li>
                            <li><NavLink to="/" className="language">HU</NavLink></li>
                        </ul>
                        <div className="toggle__btn">
                            <RxHamburgerMenu onClick={handleClick} />
                        </div>
                        <div className={`mobile__dropdown ${isOpen ? "open-en" : ""}`} onClick={handleClose}>
                            <ul>

                                <li><NavLink to="/wholesale">Wholesale</NavLink></li>
                                <li><NavLink to="/packaging">Packaging</NavLink></li>
                                <li><NavLink to="/company">Company</NavLink></li>
                                <li><NavLink to="/products">Products</NavLink></li>
                                <li><NavLink to="/contact">Contact</NavLink></li>
                                <li><NavLink to="/" className="language">HU</NavLink></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Header;