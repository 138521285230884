import './wholesale.scss';

import wholesale from '../../assets/images/wholesale-big.png';
import useGaTracker from '../../hooks/useGaTracker';
import usePageTitle from '../../hooks/usePageTitle';

export default function () {
    useGaTracker();
    usePageTitle('Wholesale');
    
    return (
        <div>
            <div className='bg__color'>
                <h1>Wholesale trade</h1>
            </div>
            <div className='wrapper-container'>
                <div className="row wholesale">
                    <div className="col-50">
                        <img className='wholesale__img' src={wholesale} alt="" />
                    </div>
                    <div className="col-50">
                        <div className='wholesale__box'>
                            <div>
                                <p>
                                    In our wholesale warehouse since 2009 we have been striving to continuously expand our range of products in addition to our permanent products.
                                </p>
                                <p>
                                    We primarily fulfill the orders of wholesale units, we now deliver to the entire country, our resale partners can be found in almost every county.
                                </p>
                                <p>
                                    We are proud of the fact that among our constantly expanding customers there are a large number of companies that have been buying from us since the beginning.
                                </p>
                                <p>
                                    It has always been an important aspect for us that the products we sell represent good quality, but are affordable for a wide range of consumers.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <iframe className='bottom__map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2668.2186473540396!2d20.817254014651553!3d48.02880365803325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4740a16066f96e83%3A0xecdc2ba2ea18de84!2sSzild%C3%A1n%20Kft!5e0!3m2!1sen!2shu!4v1682667727369!5m2!1sen!2shu" style={{ border: 0 }} title="Szildán Kft. telephelye a Google térképen"></iframe>
            </div>
        </div>
    );
}