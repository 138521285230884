import './competition.scss';
import szechenyiallo from '../../assets/images/competition/szechenyi_allo.jpg'
import szechenyifekvo from '../../assets/images/competition/szechenyi_fekvo.jpg'
import szechenyi10mil from '../../assets/images/competition/szechenyi10mil.jpg'
import useGaTracker from '../../hooks/useGaTracker';
import usePageTitle from '../../hooks/usePageTitle';

function Competition() {
    useGaTracker();
    usePageTitle('Pályázatok');
    
    return (
        <div className="competition">
            <div className='bg__color'>
                <h1>Pályázatok</h1>
            </div>
            <div className="wrapper-container text-center">
                <img src={szechenyiallo} className='competition-image' alt="Széchenyi pályázat" />
                <img src={szechenyifekvo} className='competition-image' alt="Széchenyi pályázat" />
                <img src={szechenyi10mil} className='competition-image' alt="Széchenyi pályázat" />
            </div>
        </div>
    );
}

export default Competition;