import useGaTracker from '../../hooks/useGaTracker';
import usePageTitle from '../../hooks/usePageTitle';
import './our-company.scss';

function Notfound() {
    useGaTracker();
    usePageTitle('Cégünkről');
    
    return (
        <div className="our-company">
            <div className='bg__color'>
                <h1>Cégünkről</h1>
            </div>
            <div className="wrapper-container">
                <div className='activity'>
                    <div className='activity__paragraph'>
                        <div className='flex'>
                            <span></span>
                        </div>
                        <div className='flex'>
                            <div className='company__content'>
                                <h2>2009</h2>
                                <p>A Szildán Nagykereskedelem 2009 ben alakult Mályi telephellyel. Tartós élelmiszeripari termékek nagykereskedelmi forgalmával, saját élelmiszeripari termékek előállíttatásával és értékesítésével kívánt foglalkozni.</p>
                            </div>
                        </div>
                    </div>
                    <div className='activity__paragraph'>
                        <div className='flex'>
                            <div className='company__content'>
                                <h2>2012</h2>
                                <p>Védjegyoltalmak bejegyzése. /Maestro Pietro, PURE LAND, Soul márka nevek/ Saját márkás termékek előállíttatása, gyártatása.</p>
                            </div>
                        </div>
                        <div className='flex'>
                            <span></span>
                        </div>
                    </div>
                    <div className='activity__paragraph'>
                        <div className='flex'>
                            <span></span>
                        </div>
                        <div className='flex'>
                            <div className='company__content'>
                                <h2>2016</h2>
                                <p>A Szildán Kft. Szerencsi-Ond Telep út 1sz alatti telephely megvásárlásával szárazáru csomagoló üzem működtetését kezdte. A csomagolt termékek a már meglévő nagykereskedelmi partnereknek, illetve multinacionális hálózatoknak kerülnek értékesítésre.</p>
                            </div>
                        </div>
                    </div>
                    <div className='activity__paragraph'>
                        <div className='flex'>
                            <div className='company__content'>
                                <h2>2020</h2>
                                <p>A folyamatos termékkör, illetve forgalmi növekedés megkívánta a raktározási kapacitás bővítését ezért kiépítésre került egy korszerű polcrendszeres tároló. Hűtőkamra alapterületi bővítése.</p>
                            </div>
                        </div>
                        <div className='flex'>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Notfound;